export default class Navbar {
    constructor() {
        Navbar.sticky()
        Navbar.burger()
        Navbar.langs_switcher()
        Navbar.menu_initializer()
        Navbar.infos_live()
    }

    static sticky() {
        let last_scroll_top = 0

        $('body').addClass('logo-color-classic')

        $(window).on('load scroll', function () {
            const offset = 0 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const backtotop = $('[data-id="wpiris-scrolltop"]')
            const scroll_top = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scroll_top >= last_scroll_top && scroll_top > 0) {
                $('body').addClass('header-is-hidden')
                backtotop.addClass('is-active')
            } else {
                $('body').removeClass('header-is-hidden')
                backtotop.removeClass('is-active')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
                $('body').removeClass('logo-color-classic')
                $('body').addClass('logo-color-banner')
            }

            last_scroll_top = scroll_top

            if (scroll_top > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                $('body').addClass('header-is-onscroll')
                if (full === true) {
                    Navbar.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                $('body').removeClass('header-is-onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    Navbar.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logo_banner
        let logo_classic

        logo_banner = IRISCollectionCustomer.imageLogoBanner
        logo_classic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logo_banner)
            $('body').removeClass('logo-color-classic')
            $('body').addClass('logo-color-banner')
        } else if (color === 'classic') {
            logo.attr('src', logo_classic)
            $('body').removeClass('logo-color-banner')
            $('body').addClass('logo-color-classic')
        }
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open')
            if ($('body').hasClass('menu-open')) {
                $('[data-id="wpiris-logo"]').attr(
                    'src',
                    'https://www.tourisme-en-aubrac.com/app/iris-optimize/theme_customer/images/logo-banner.svg',
                )
            } else if ($('body').hasClass('logo-color-classic')) {
                $('[data-id="wpiris-logo"]').attr(
                    'src',
                    'https://www.tourisme-en-aubrac.com/app/iris-optimize/theme_customer/images/logo.svg',
                )
            }
            if (window.matchMedia('(max-width: 1000px)').matches) {
                $('#eco-bar').addClass('eco-bar--hidden')
            }
        })

        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        if (window.matchMedia('(max-width: 1000px)').matches) {
            $('.selected-submenu').removeClass('selected-submenu')
        }

        $(prev).on('click', (e) => {
            e.preventDefault()
            $('.selected-submenu').removeClass('selected-submenu')
            $('.header__menu').removeClass('submenu-is-open')
        })
    }

    static langs_switcher() {
        $(window).on('load', () => {
            setTimeout(() => {
                const current_language = $('html').attr('lang').replace(/-.*/gi, '').toUpperCase()

                $('.lang__current').text(current_language)

                $('.glink').each(function () {
                    if ($(this).text() === current_language) {
                        $(this).css('display', 'none')
                    } else {
                        $(this).css('display', 'block')
                    }
                })
            }, 1000)
        })
    }

    static menu_initializer() {
        $('.menu__item').on('click', function () {
            $('.menu__item').each(function () {
                $(this).removeClass('selected-submenu')
            })
            $(this).addClass('selected-submenu')
            $('.header__menu').addClass('submenu-is-open')
        })
    }

    static infos_live() {
        $('.live__select').on('click', function () {
            $(this).toggleClass('active')
        })

        $('.infos-live__select__option').on('click', function () {
            if ($('.live__select').hasClass('active')) {
                $('.infos-live__select__option').removeClass('selected')
                $(this).toggleClass('selected')
                weather_init($(this).attr('value'))
            }
        })

        function weather_init(id) {
            const $active_list = $(`.infos-live__content__weathers__list--${id}`)

            if ($('.info-neige').length > 0) {
                $('.info-neige').removeClass('info-neige--active')

                if (id === 1) {
                    $('.info-neige__aubrac').addClass('info-neige--active')
                } else {
                    $('.info-neige__custom').addClass('info-neige--active')
                }
            }

            $(
                '.infos-live__content__weathers__list:not(.infos-live__content__weathers__list--snow-infos)',
            ).removeClass('infos-live__content__weathers__list--active')
            $active_list.addClass('infos-live__content__weathers__list--active')
        }
    }
}
