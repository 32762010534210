export default class Banner {
    constructor() {
        Banner.smooth_scroll()
    }

    static smooth_scroll() {
        const trigger = '[data-trigger="wpiris-scroll"]:not(.backtotop)'

        $(document).on('click', trigger, function (e) {
            e.preventDefault()

            const target =
                $(this).attr('href') !== '' ? $(this).attr('href') : $(this).attr('data-href')
            const duration = 1000

            let scroll_top = 0

            if ($(target).length > 0 && $(target).offset().top) {
                scroll_top =
                    $(target).offset().top -
                    (undefined !== e.currentTarget.dataset.shift
                        ? e.currentTarget.dataset.shift
                        : 0)
            }

            $('html, body').animate(
                {
                    scrollTop: scroll_top,
                },
                {
                    duration: duration,
                    step: (now, fx) => {
                        const real_pos = scroll_top
                        if (fx.end !== real_pos) {
                            fx.end = real_pos
                        }
                    },
                },
            )
        })
    }
}
